import React, {useState, useEffect} from 'react';
import '../styles/printMenu.less';
import dayjs from "dayjs";

const localeDays = {
    Monday: 'Montag',
    Tuesday: 'Dienstag',
    Wednesday: 'Mittwoch',
    Thursday: 'Donnerstag',
    Friday: 'Freitag'
}

function PrintContainer(props) {
    const {menus} = props;
    const [sortedMenus, setSortedMenus] = useState({})

    useEffect(() => {
        orderMenus()
    }, [menus])

    function orderMenus() {
        let cwSortedMenus = {};
        let daySortedMenus = {};
        menus.forEach(menu => {
            if (cwSortedMenus.hasOwnProperty(menu.calendar_week)) {
                cwSortedMenus[menu.calendar_week].push(menu);
            } else {
                Object.assign(cwSortedMenus, {[menu.calendar_week]: [menu]})
            }
        })
        Object.keys(cwSortedMenus).forEach(cw => {
            Object.assign(daySortedMenus, {[cw]: {}})
            cwSortedMenus[cw].forEach(cwMenu => {
                if (daySortedMenus[cw].hasOwnProperty(cwMenu.weekday)) {
                    daySortedMenus[cw][cwMenu.weekday].push(cwMenu)
                } else {
                    // daySortedMenus[cw].push({[cwMenu.weekday]: [cwMenu]})
                    Object.assign(daySortedMenus[cw], {[cwMenu.weekday]: [cwMenu]})
                }
            })
        })
        setSortedMenus(daySortedMenus);
    }

    return (
        <div className={'printContainer'}>
            {Object.keys(sortedMenus).map((cw, index) => {
                return (
                    <div className={'cwContainer'}>
                        <p key={index} className={'cwHeadline'}>Menüs für Kalenderwoche: {cw}</p>
                        <div>{Object.keys(sortedMenus[cw]).map((day, dayIndex) => {
                            return (
                                <div className={'weekday'}>
                                <p className={'weekdayHeadline'}>{localeDays[day]}, {dayjs(sortedMenus[cw][day][0]['date']).format('DD.MM.YYYY')}</p>
                                <table width={'100%'}>
                                    {sortedMenus[cw][day].map((menu, menuIndex) =>
                                    <tr style={{padding: '10px 0'}}>
                                        <td width={'10%'}>Menü {menuIndex +1}</td>
                                        <td width={'70%'}>{menu.menu_name}</td>
                                        <td width={'10%'}>{menu.price.toFixed(2)} €</td>
                                        <td width={'10%'} style={{fontSize: 12}}>{menu.additions}</td>
                                    </tr>
                                    )}
                                </table>
                                </div>
                            )
                        })}</div>
                    </div>
                )
            })}
        </div>
    );
}

export default PrintContainer;
