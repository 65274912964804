import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Alert, Button, Input} from "rsuite";
import '../styles/component.less';
import {API_HOST} from "../helper/constants";

function Dashboard(props) {
    const [info, setInfo] = useState('');

    function getInfoformApi() {
        axios.get(API_HOST + '/news')
            .then(response => {
                if (response.status < 300) {
                    setInfo(response.data.info[0].info)
                }
            })
            .catch(exp => {
                Alert.error('Info konnte nicht abgerufen werden: ' + exp);
            })
    };
    useEffect(() => {
        getInfoformApi()
    },[]);
    function saveNewInfo() {
        let token = localStorage.getItem('sh-token');
        axios.put(API_HOST + '/info/1', {info: info}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.status < 300) {
                    Alert.success('Info/News wurde gespeichert!')
                    setInfo(response.data.info.info);
                } else {
                    Alert.warning('Info konnte nicht gespeichert werden, bitte nochmal versuchen!')
                }
            })
            .catch(exp => {
                Alert.error('Fehler beim Speicher der Info: ' + exp);
            })
    }

    return (
        <div className={'component-root'}>
            <h1>Willkommen zur Menü-Verwaltung!</h1>
            <p>Info/News für die Bestellseite anlegen.</p>
            <Input
                componentClass="textarea"
                rows={3}
                style={{ width: 400, resize: 'auto' }}
                placeholder="Infos"
                defaultValue={info}
                onChange={value => setInfo(value)}
            />
            <Button onClick={() => saveNewInfo()} color={'green'}>Info Speichern</Button>
        </div>
    );
}

export default Dashboard;