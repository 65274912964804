import React, {useState} from 'react';
import {Alert, Button, Modal, DatePicker, Divider, FlexboxGrid, Icon, Panel} from "rsuite";
import {API_HOST, locale} from "../../helper/constants";
import axios from "axios";
import dayjs from "dayjs";
import MenuForm from "../../Components/AddMenu/MenuForm";


function EditMenu(props) {
    const [menuDay, setMenuDay] = useState();
    const [menus, setMenus] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [menuToDelete, setMenuToDelete] = useState(null);
    const [locationValues, setLocationValues] = useState([])

    function handleChange(index, name, value) {
        let newMenu = menus;
        if (value === 'isSpecial') {
            newMenu[index].isSpecial = !menus[index].isSpecial;
        } else {
            newMenu[index][name] = value;
        }

        setMenus([...newMenu]);
    };
    function handleChangeLocation(index, value) {
        let locations = locationValues;
        locations[index] = value
        setLocationValues([...locations]);
    }
    function handelConfirmDialog(id) {
        setMenuToDelete(id)
        setShowConfirmDialog(true)
    }
    function handleMenuDelete(id) {
        let token = localStorage.getItem('sh-token');
        let deletedMenuIndex = menus.findIndex(f => f.id === id);
        let newMenus = menus;
        axios.delete(`${API_HOST}/menu/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (response.status < 300) {
                newMenus.splice(deletedMenuIndex, 1);
                setMenus([...newMenus])
                Alert.success('Menü wurde geslöscht!')
            }
        }).catch(exp => {
            Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
        })
        setShowConfirmDialog(false)
    }
    function convertMenu(menus) {
        let menuToSave = [];
        let locations = []
        menus.forEach((data, index) => {
          menuToSave.push({
              id: data.id,
              menu_id: data.menu_id.slice(4, 5),
              menuName: data.menu_name,
              menuNameEn: data.menu_name_en,
              weekday: data.weekday,
              date: data.date,
              calendar_week: data.calendar_week,
              price: data.price,
              isSpecial: data.is_special,
              closed: data.closed,
              additions: data.additions.split('; '),
              rsg_cham: data.rsg_cham,
              jvfg_cham: data.jvfg_cham,
              kantine10: data.kantine10,
              crown: data.crown,
              r27: data.r27
          })
            locations.push([]);
            if (data.rsg_cham === 1) {
                locations[index].push('rsg_cham');
            }
            if (data.jvfg_cham === 1) {
                locations[index].push('jvfg_cham');
            }
            if (data.kantine10 === 1) {
                locations[index].push('kantine10');
            }
            if (data.crown === 1) {
                locations[index].push('crown');
            }
            if (data.r27 === 1) {
                locations[index].push('r27');
            }
        })
        setMenus([...menuToSave]);
        setLocationValues([...locations])
    };
    const loadMenus = (date) => {
        setMenuDay(date);
        let token = localStorage.getItem('sh-token');
        axios.get(`${API_HOST}/menus?date=${dayjs(date).format('YYYY-MM-DD')}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            if (date !== null) {
                if (response.status < 300 && response.data.menu.length > 0) {
                    convertMenu(response.data.menu);
                } else {
                    Alert.warning('Für diesen Tag sind keine Menüs vorhanden');
                }
            }
        }).catch(exp => {
            Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
        })
    };
    function cleanMenu() {
        setMenus([]);
        setMenuDay('');
    };
    function updateMenu() {
        let token = localStorage.getItem('sh-token');
        let menuToSave = {};

        if (locationValues.length <= 0) {
            Alert.error('Bitte wählen Sie mindestens eine Kantine!');
        } else {
        if (menus.length > 0) {
            menus.forEach((menuItem, index) => {
                let additionsString = menuItem.additions.length > 0 && menuItem.additions.reduce((acc, cur) => acc + '; ' + cur)
                let locations = {
                    jvfg_cham: false,
                    rsg_cham: false,
                    kantine10: false,
                    crown: false,
                    r27: false
                };
                locationValues[index].forEach(item => locations[item] = true);
                menuToSave = {
                    menu_name: menuItem.menuName,
                    menu_name_en: menuItem.menuNameEn,
                    menu_id: 'menu' + menuItem.menu_id,
                    weekday: dayjs(menuDay).format('dddd'),
                    date: dayjs(menuDay).format('YYYY-MM-DD'),
                    calendar_week: dayjs(menuDay).week(),
                    price: typeof menuItem.price === "string" ? parseFloat(menuItem.price.replace(',', '.')) : menuItem.price,
                    is_special: menuItem.isSpecial ? 1 : 0,
                    closed: 0,
                    additions: additionsString
                }
                console.log(locations)
                Object.assign(menuToSave, locations)

                axios.put(`${API_HOST}/menu/${menuItem.id}`, menuToSave, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(response => {
                    if (response.status < 300) {
                        //convertMenu(response.data.menu);
                        Alert.success('Menüs wurden gespeichert')
                    }
                }).catch(exp => {
                    Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
                })
            })

        }
        }
    };
return (
    <div className={'component-root'}>
        <h1>Menüs Bearbeiten</h1>
        <Panel header={(<span><Icon icon={'pencil'}/> Menüs auswählen</span>)} bordered>
            <FlexboxGrid justify={'space-between'}>
                <FlexboxGrid.Item colspan={24}>
                    <DatePicker
                        size="lg"
                        placeholder="Tag wählen"
                        format={'DD.MM.YYYY'}
                        isoWeek
                        showWeekNumbers
                        oneTap
                        block
                        value={menuDay}
                        onChange={(date) => loadMenus(date)}
                        onClean={() => cleanMenu()}
                        locale={locale}
                    />
                </FlexboxGrid.Item>
            </FlexboxGrid>
            <Divider />
            {menus.map((item, index) => {
                return (
                    <MenuForm key={item.id + '_' + index}
                              index={index} menu={item}
                              onChange={handleChange}
                              disabled={false}
                              deleteMenu={handelConfirmDialog}
                              locationValues={locationValues[index]}
                              handleChangeLocation={handleChangeLocation}
                              isEdit
                    />
                )
            })}
            <Button appearance="primary" color={'green'} style={{float: 'right'}} ripple onClick={() => updateMenu()}><Icon
                icon={'user-plus'}/>
                &nbsp;Menüs Speichern
            </Button>
            <div style={{clear: 'both'}} />
        </Panel>
        <Modal show={showConfirmDialog} onHide={() => setShowConfirmDialog(false)}>
            <Modal.Header>
                <Modal.Title>Menü Löschen?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Wollen sie das ausgewählte Menü wircklich löschen?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleMenuDelete(menuToDelete)} appearance="primary" color={'red'}>
                    Löschen
                </Button>
                <Button onClick={() => setShowConfirmDialog(false)} appearance="subtle">
                    Abbrechen
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
)
}

export default EditMenu;