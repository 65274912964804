import React, {useState} from 'react';
import '../../styles/component.less';
// import PropTypes from 'prop-types';
import {InputGroup, Input, Button, Alert, Panel, Icon, Schema, FlexboxGrid} from "rsuite";
import axios from 'axios';
import {API_HOST} from "../../helper/constants";

// AddUser.propTypes = {};

function AddUser(props) {
    const [newUser, setNewUser] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
    })
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [password_confirmationError, setPasswordConfError] = useState(false);

    const {StringType} = Schema.Types;
    const userModel = Schema.Model({
        name: StringType().isRequired('Bitte Name eingeben'),
        email: StringType().isEmail('Bitte E-Mail Adresse eingeben'),
        password: StringType().minLength(6, 'Passwort sollte mind. 6 Zeichen Lang sein').isRequired('Passwort wird Benötigt')
    })

    function handleText(value, type) {
        setNewUser({
            ...newUser,
            [type]: value
        });
        switch (type) {
            case 'name':
                setNameError(false);
                break;
            case 'email':
                setEmailError(false);
                break;
            case 'password':
                setPasswordError(false);
                break;
            case 'password_confermation':
                setPasswordConfError(false);
                break;
            default:
                return;
        }
    };

    function addNewUser() {
        if (newUser.password !== newUser.password_confirmation) {
            Alert.error('Passwörter stimmen nicht überein!')
        }
        let error = false;

        const checkResult = userModel.check({
            name: newUser.name,
            email: newUser.email,
            password: newUser.password
        })

        if (checkResult.name.hasError) {
            Alert.error(checkResult.name.errorMessage);
            error = true;
        }
        if (checkResult.email.hasError) {
            Alert.error(checkResult.email.errorMessage);
            error = true;
        }
        if (checkResult.password.hasError) {
            Alert.error(checkResult.password.errorMessage);
            error = true;
        }

        console.log(checkResult)

        if (!error) {
            saveUser(newUser);
        }
    };

    function saveUser(userData) {
        axios.post(API_HOST + '/register', userData)
            .then(response => {
                if (response.status === 200) {
                    Alert.success('Neuer Benutzer wurde Angelegt')
                }
                setNewUser(
                    {
                        name: '',
                        email: '',
                        password: '',
                        password_confirmation: ''
                    }
                )
            })
            .catch(exp => {
                Alert.error('Leider ist ein Fehler aufgetretten: ' + exp)
            })
    }

    function resetUser() {
        setNewUser(
            {
                name: '',
                email: '',
                password: '',
                password_confirmation: ''
            }
        )
    }

    return (
        <div className={'component-root'}>
            <h1>Neuen Benutzer Anlegen</h1>
            <Panel header={(<span><Icon icon={'user-plus'}/> Neuer Benutzer</span>)} bordered>
                <InputGroup className={'input-group'}>
                    <InputGroup.Addon> <Icon icon={'user'}/> </InputGroup.Addon>
                    <Input type={'text'}
                           className={nameError && 'error'}
                           placeholder={'Benutzer Name'}
                           value={newUser.name}
                           onChange={(value) => handleText(value, 'name')}
                    />
                </InputGroup>
                <InputGroup className={'input-group'}>
                    <InputGroup.Addon> <Icon icon={'envelope'}/> </InputGroup.Addon>
                    <Input type={'text'}
                           className={emailError && 'error'}
                           placeholder={'E-Mail'}
                           value={newUser.email}
                           onChange={(value) => handleText(value, 'email')}
                    />
                </InputGroup>
                <InputGroup className={'input-group'}>
                    <InputGroup.Addon> <Icon icon={'lock'}/> </InputGroup.Addon>
                    <Input type={'password'}
                           className={passwordError && 'error'}
                           placeholder={'Passwort'}
                           value={newUser.password}
                           onChange={(value) => handleText(value, 'password')}
                    />
                </InputGroup>
                <InputGroup className={'input-group'}>
                    <InputGroup.Addon> <Icon icon={'lock'}/> </InputGroup.Addon>
                    <Input type={'password'}
                           className={password_confirmationError && 'error'}
                           placeholder={'Passwort wiederholen'}
                           value={newUser.password_confirmation}
                           onChange={(value) => handleText(value, 'password_confirmation')}
                    />
                </InputGroup>
                <FlexboxGrid justify={'space-between'}>
                    <FlexboxGrid.Item colspan={10}>
                        <Button appearance="ghost" block ripple onClick={() => resetUser()}>
                            <Icon icon={'times-circle'}/>
                             &nbsp;Reset
                        </Button>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={10}>
                        <Button appearance="primary" color={'green'} block ripple onClick={() => addNewUser()}><Icon
                            icon={'user-plus'}/>
                            &nbsp;Benutzer Speichern
                        </Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
        </div>
    );
}

export default AddUser;