import React, {useState} from 'react';
import 'rsuite/lib/styles/themes/dark/index.less';
import './styles/app-styles.less';
import axios from 'axios';
import Login from "./Containers/Login";
import {Alert} from "rsuite";
import AdminContainer from "./Containers/AdminContainer";
import AddUser from "./Containers/AdminContainer/AddUser";
import AddMenu from "./Containers/AdminContainer/AddMenu";
import EditMenu from "./Containers/AdminContainer/EditMenu";
import Orders from './Containers/AdminContainer/Orders';
import Dashboard from "./Containers/Dashboard";
import {API_HOST} from "./helper/constants";
import PrintMenus from "./Containers/PrintMenus";


function App(props) {
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(localStorage.getItem('sh-token') ? localStorage.getItem('sh-token') : '');
    const [activePage, setActivePage] = useState(1);
    function loginCallback(loginData) {
        axios.post(API_HOST + '/login', loginData)

            .then(response => {
                console.log(response)
                if (response.status > 199 && response.status < 300) {
                    if (response.data.message && response.data.message === 'Invalid Credentials') {
                        Alert.error('Wrong Username or Password!', 3000)
                    } else {
                        setUserData(response.data.userData);
                        setToken(response.data.access_token);
                        localStorage.setItem('sh-token', response.data.access_token);
                    }
                }
            })
            .catch(exp => {
                Alert.error('Wrong Username or Password!', 3000)
                console.log('Exeption:', exp);
            })
    }
    function onLogOut() {
        setUserData({});
        setToken('');
        localStorage.removeItem('sh-token');
    }
    function handleNavigation(navItem) {
        setActivePage(navItem);
    }

    //Return of Components

    if (token === '') {
        return (
            <div className="App">
                <Login callback={loginCallback}/>
            </div>
        );
    } else {
        return (
            <div className={"App"}>
                <AdminContainer userData={userData} onLogout={onLogOut} handleNav={handleNavigation} activePage={activePage}>
                    {activePage === 1 &&
                        <Dashboard />
                    }
                    {activePage === 2 &&
                        <AddMenu />
                    }
                    {activePage === 3 &&
                        <EditMenu />
                    }
                    {activePage === 4 &&
                        <PrintMenus />
                    }
                    {activePage === 5 &&
                        <Orders />
                    }
                    {activePage === 6 &&
                        <AddUser />
                    }
                </AdminContainer>
            </div>
        )
    }
}

export default App;
