import React from 'react';
import PropTypes from 'prop-types';
import '../styles/adminContainer-styles.less';
import { Sidenav, Nav, FlexboxGrid, Icon } from 'rsuite';


AdminContainer.propTypes = {
    userData: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
    activePage: PropTypes.number.isRequired,
    onLogout: PropTypes.func.isRequired,
    handleNav: PropTypes.func.isRequired,
};

function AdminContainer(props) {
    return (
        <div className={'admin-container-root'}>
            <FlexboxGrid className={'admin-flex'}>
                <FlexboxGrid.Item colspan={4} style={{height: '100%'}}>
                    <Sidenav activeKey={''+ props.activePage+ ''} style={{height: '100%'}}>
                        <Sidenav.Body>
                            <Nav className={'noPrint'}>
                                <Nav.Item onClick={() => props.handleNav(1)} eventKey={'1'} icon={<Icon icon={'dashboard'} />}>
                                    Dashboard
                                </Nav.Item>
                                <Nav.Item onClick={() => props.handleNav(2)} eventKey={'2'} icon={<Icon icon={'plus-circle'} />}>
                                    Neues Menü
                                </Nav.Item>
                                <Nav.Item onClick={() => props.handleNav(3)} eventKey={'3'} icon={<Icon icon={'pencil'} />}>
                                    Menüs bearbeiten
                                </Nav.Item>
                                <Nav.Item onClick={() => props.handleNav(4)} eventKey={'4'} icon={<Icon icon={'print'} />}>
                                    Menüs Drucken
                                </Nav.Item>
                                <Nav.Item onClick={() => props.handleNav(5)} eventKey={'5'} icon={<Icon icon={'th-list'} />}>
                                    Bestellungen
                                </Nav.Item>
                                <Nav.Item onClick={() => props.handleNav(6)} eventKey={'6'} icon={<Icon icon={'user-plus'} />}>
                                    Neuer Benutzer
                                </Nav.Item>
                                <Nav.Item onClick={() => props.onLogout()} eventKey={'7'} icon={<Icon icon={'sign-out'} />}>
                                    Abmelden
                                </Nav.Item>
                            </Nav>
                        </Sidenav.Body>
                    </Sidenav>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={20}>
                    {props.children}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </div>
    );
}

export default AdminContainer;