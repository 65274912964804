import React from 'react';
import PropTypes from 'prop-types';
import {Table} from "rsuite";
import dayjs from "dayjs";


OrdersTable.propTypes = {
    orders: PropTypes.array.isRequired,
};

function OrdersTable(props) {

    const DateCell = ({rowData, dataKey, ...props}) => (
        <Table.Cell {...props}>
            {dayjs(rowData[dataKey]).format('DD.MM.YYYY')}
        </Table.Cell>
    );

    const DayCell = ({rowData, dataKey, ...props}) => (
        <Table.Cell {...props}>
            {[rowData[dataKey]]}
        </Table.Cell>
    )
    const FullCell = ({rowData, dataKey, ...props}) => {
        return(
        <Table.Cell {...props}>
            {rowData[dataKey] === 1 ? 'Ja' : 'Nein'}
        </Table.Cell>
    )}

    return (
        <Table
            autoHeight
            width={1920}
            className={'printOrderTable'}
            data={props.orders}
            onRowClick={data => {
                console.log(data);
            }}
        >
            <Table.Column width={70} align="center" fixed>
                <Table.HeaderCell>Id</Table.HeaderCell>
                <Table.Cell dataKey="id" />
            </Table.Column>
            <Table.Column width={130} >
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.Cell dataKey="name" />
            </Table.Column>
            <Table.Column width={130} >
                <Table.HeaderCell>Tag</Table.HeaderCell>
                <DayCell dataKey="weekday" />
            </Table.Column>
					{props.location === 'crown' &&
					<Table.Column width={130} >
						<Table.HeaderCell>Uhrzeit</Table.HeaderCell>
						<Table.Cell dataKey="day_time" />
					</Table.Column>
					}
            <Table.Column width={130} >
                <Table.HeaderCell>Datum</Table.HeaderCell>
                <DateCell dataKey={'date'} />
            </Table.Column>
            <Table.Column width={130} >
                <Table.HeaderCell>KW</Table.HeaderCell>
                <Table.Cell dataKey="calendar_week" />
            </Table.Column>
            <Table.Column width={130} >
                <Table.HeaderCell>Menü Nummer</Table.HeaderCell>
                <Table.Cell dataKey="menu" />
            </Table.Column>
            <Table.Column width={130} >
                <Table.HeaderCell>Menü Komplett</Table.HeaderCell>
                <FullCell dataKey="fullMenu" />
            </Table.Column>
					{props.location === 'crown' &&
					<Table.Column width={130} >
						<Table.HeaderCell>Werk</Table.HeaderCell>
						<Table.Cell dataKey="werk" />
					</Table.Column>
					}
        </Table>
    );
}

export default OrdersTable;
